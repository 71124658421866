import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Panel, ButtonBar, Input, Modal, Notification } from "@k8slens/lds";
import clsx from "clsx";
import type { SubscriptionChangePreview } from "lens-platform-sdk";

import { useAnalytics } from "src/hooks/useAnalytics";
import { getPlanData, getPriceData, PlanCode } from "src/services/plans";
import { formatCurrency } from "src/utils/currency";
import { useGetBusinessSubscription } from "src/hooks/useGetBusinessSubscription";
import { useUpdateBusinessSubscription } from "src/hooks/useUpdateBusinessSubscription";
import { useGetPendingBusinessSubscriptionInvitations } from "src/hooks/useGetPendingBusinessInvitations";
import { dateToString } from "src/utils/date";
import { trimUUID } from "src/services/trimUUID";
import { usePreviewBusinessSubscriptionUpdate } from "src/hooks/usePreviewBusinessSubscriptionChange";

import Button from "src/components/Button/TrackedButton";
import FeatureList from "src/components/FeatureList/FeatureList";
import LicenseImage from "src/components/LicenseImage/LicenseImage";
import PublicLayout from "src/components/PublicLayout/PublicLayout";
import LinkButton from "src/components/Button/TrackedRouterButton";

import ChangeSeatsOrderSummary from "./ChangeSeatsOrderSummary";

import styles from "./page.module.css";

export interface Props {
  loading?: boolean;
  planCode: PlanCode;
  quantity: number;
  onConfigure(planCode: PlanCode): Promise<boolean>;
}

const UpdateBusinessSubscription = () => {
  const { businessId, subscriptionId } = useParams<{
    businessId: string;
    subscriptionId: string;
  }>();
  const { track } = useAnalytics();
  const { subscription } = useGetBusinessSubscription(businessId, subscriptionId);
  const { previewSubscriptionChange, updatingSubscriptionChangePreview } = usePreviewBusinessSubscriptionUpdate();
  const { updateBusinessSubscription, error, updating } = useUpdateBusinessSubscription();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const pendingBusinessSubscriptionInvitations = useGetPendingBusinessSubscriptionInvitations(
    businessId,
    subscriptionId,
  );
  const history = useHistory();
  const [seatInputValue, setSeatInputValue] = useState<number>(0);

  const [orderSummary, setOrderSummary] = useState<SubscriptionChangePreview>();

  // User should not be able to downgrade subscription seats number to
  // less than already used seats + pending invitations
  const minimumSeats = useMemo(
    () => (subscription?.usedSeats.length || 0) + pendingBusinessSubscriptionInvitations.length,
    [pendingBusinessSubscriptionInvitations.length, subscription?.usedSeats.length],
  );

  const planData = getPlanData(subscription?.planCode as PlanCode);
  const priceData = getPriceData(subscription?.planCode as PlanCode);

  useEffect(() => {
    if (!seatInputValue && subscription?.seats) {
      setSeatInputValue(subscription.seats);
    }
  }, [subscription, seatInputValue]);

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10);

    const preview = await previewSubscriptionChange({
      businessId,
      businessSubscriptionId: subscriptionId,
      quantity: value,
    }).catch(() => {});

    setOrderSummary(preview as SubscriptionChangePreview);

    setSeatInputValue(value);
  };

  const returnUrl = useMemo(() => {
    return `/business/${businessId}/billing-details`;
  }, [businessId]);

  const handleUpdateSubscription = async () => {
    if (!seatInputValue || !subscription) {
      return;
    }

    // If downgrading number of seats - first show modal with disclaimer and confirmation
    if (seatInputValue < subscription.seats && !showConfirmModal) {
      setShowConfirmModal(true);

      return;
    }

    const updated = await updateBusinessSubscription(businessId, subscriptionId, seatInputValue);

    if (updated) {
      setShowConfirmModal(false);
      track(
        `Change subscription ${planData.quantity.plural} quantity from ${subscription?.seats} to ${seatInputValue}`,
      );

      // Redirect to business details after successful seats update
      history.push(returnUrl);
    }
  };

  if (!subscription) {
    return null;
  }

  return (
    <PublicLayout size="xl" className="bg-secondary">
      <div className={styles.updateSubscription}>
        {error && <Notification type="flash" level="error" message={error.message} />}
        <Panel
          className={styles.configure}
          footer={
            <>
              <LinkButton to={returnUrl} label="Cancel" discreet textTransform="uppercase" />
              <Button
                label="Update subscription"
                disabled={seatInputValue !== 0 && subscription.seats === seatInputValue}
                primary
                textTransform="uppercase"
                loading={updating || updatingSubscriptionChangePreview}
                loadingStateOnPromise
                onClick={() => handleUpdateSubscription()}
              />
            </>
          }
          footerComponent={ButtonBar}
          footerProps={{
            as: "footer",
            className: "mt-0",
          }}
          header="Manage Subscription"
          headerComponent="h2"
          headerProps={{ className: clsx("lds-h1", styles.configureHeader) }}
        >
          <section className={styles.planInfo}>
            <LicenseImage type={planData.type} className={styles.licenseImage} />
            <FeatureList
              className={styles.featureList}
              title={`Included in Lens ${planData.nameShort}`}
              features={planData.features}
            />
          </section>
          <section className={styles.seats}>
            <h4>Current {planData.quantity.plural}</h4>
            <div className={styles.currentSubscription}>
              <span>
                Subscription <span className={styles.highLight}>{trimUUID(subscriptionId)}</span>
              </span>
              <span className={styles.assignedSeats}>
                <span className={styles.highLight}>
                  {minimumSeats} / {subscription?.seats}
                </span>{" "}
                {subscription?.seats === 1 ? planData.quantity.singular : planData.quantity.plural} assigned
              </span>
            </div>
          </section>
          <section className={styles.newSeats}>
            <div className={styles.seatCount}>
              <h4>New total {planData.quantity.plural}</h4>
              <p>Enter the new total number of {planData.quantity.plural}.</p>
              <div className={styles.seatCountContent}>
                <Input
                  autoFocus
                  type="number"
                  inputMode="numeric"
                  min={minimumSeats}
                  aria-label={`Enter the new total number of ${planData.quantity.plural}`}
                  value={seatInputValue || subscription?.seats}
                  onChange={handleInputChange}
                  className={styles.seatCountInput}
                />

                <p id="seatDescription" className={styles.pricePerSeat}>
                  <span>{formatCurrency(subscription.unitAmount)}</span>
                  <span>{priceData.perSeatText}</span>
                </p>
              </div>
              {subscription?.currentPeriodEndsAt && (
                <p className={styles.remark}>
                  Downgrading the number of {planData.quantity.plural} will be effective at the end of the current
                  billing period, on{" "}
                  <span className={styles.highLight}>{dateToString(new Date(subscription.currentPeriodEndsAt))}</span>
                </p>
              )}
            </div>
          </section>
        </Panel>
        {subscription && subscription.planCode ? (
          <ChangeSeatsOrderSummary
            periodStartedAt={new Date().toDateString()}
            periodEndsAt={subscription.currentPeriodEndsAt!}
            orderSummary={orderSummary}
            oldQuantity={subscription.seats}
            quantity={seatInputValue}
            planData={planData}
            planCode={subscription.planCode as PlanCode}
            onUpdate={handleUpdateSubscription}
            loading={updating || updatingSubscriptionChangePreview}
            updateDisabled={seatInputValue !== 0 && subscription.seats === seatInputValue}
          />
        ) : null}
        {subscription?.currentPeriodEndsAt ? (
          <Modal
            isOpen={showConfirmModal}
            size="md"
            title={`Confirm change number of ${planData.quantity.plural}`}
            onClose={() => setShowConfirmModal(true)}
            footer={
              <>
                <Button
                  label="Cancel"
                  textTransform="uppercase"
                  loading={updating}
                  loadingStateOnPromise
                  onClick={() => setShowConfirmModal(false)}
                />
                <Button
                  label="Confirm"
                  primary
                  textTransform="uppercase"
                  loading={updating}
                  loadingStateOnPromise
                  onClick={() => handleUpdateSubscription()}
                />
              </>
            }
          >
            Decreasing the number of {planData.quantity.plural} will be effective at the end of current billing period,
            on <b>{dateToString(new Date(subscription.currentPeriodEndsAt))}</b>
          </Modal>
        ) : null}
      </div>
    </PublicLayout>
  );
};

export default UpdateBusinessSubscription;
