import { useContext, useMemo, useState } from "react";
import clsx from "clsx";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { BusinessSubscription, SubscriptionInfo } from "lens-platform-sdk";

import { getPlanName } from "src/services/plans";

import type { ChildBusiness } from "src/hooks/useBusinessChildren";

import Table from "src/components/Table/Table";
import SubscriptionIdCell, { subscriptionIdColDef } from "src/components/TableCells/SubscriptionIdCell";
import { dateColDef } from "src/components/TableCells/DateCell";
import { contextMenuColDef } from "src/components/TableCells/ContextMenuCell";

import styles from "./BillingDetails.module.css";
import { BusinessContext } from "src/components/Business/Base";
import { Button, ButtonBar } from "@k8slens/lds";
import { useHistory } from "react-router-dom";
import { BusinessSubscriptionContextMenu } from "./BusinessSubscriptionContextMenu";

const subscriptionColumnHelper = createColumnHelper<SubscriptionInfo | BusinessSubscription>();

export interface SubscriptionsProps {
  subscriptions: SubscriptionInfo[] | BusinessSubscription[];
  businessChildren?: ChildBusiness[];
  billingPageToken: string | null;
  loading: boolean;
}

const isBusinessSubscription = (subscription: any): subscription is BusinessSubscription =>
  subscription.isBusinessAccount === true;

export const BillingSubscriptions = ({ subscriptions, loading, businessChildren }: SubscriptionsProps) => {
  const history = useHistory();
  const { business, businessId } = useContext(BusinessContext);
  const [subscriptionsSorting, setSubscriptionsSorting] = useState<SortingState>([]);

  /**
   * Check if there are any subscriptions that are from a child business
   */
  const someChildrenSubscriptions = useMemo(() => {
    return subscriptions.some((subscription) => {
      if (isBusinessSubscription(subscription) && typeof subscription.fromChildBusinessId === "string") {
        return true;
      }

      return false;
    });
  }, [subscriptions]);

  const subscriptionsColumns = useMemo(() => {
    const commonColumns = [
      subscriptionColumnHelper.accessor("id", {
        ...subscriptionIdColDef,
        cell: (props) => <SubscriptionIdCell className={styles.subscriptionId} {...props} />,
      }),
      subscriptionColumnHelper.accessor("planCode", {
        header: () => <span className="subscriptionsPlan">Plan</span>,
        cell: ({ getValue }) => <div className="subscriptionsPlanValue">{getPlanName(getValue())}</div>,
      }),
      subscriptionColumnHelper.accessor("currentPeriodEndsAt", {
        ...dateColDef,
        header: () => <span>Next Renewal Date</span>,
      }),
      subscriptionColumnHelper.accessor("seats", {
        header: () => <span>Quantity</span>,
        cell: ({ getValue, row }) => {
          const subscription = row.original;
          const disabled = subscription.state === "expired" || business?.external;
          const title =
            "Cannot change seats for this subscriptions either its expired or this subscription is not auto renewable. Please contact your sales representative to modify the quantity.";

          return (
            <div className={styles.changeQuantityLayout}>
              <span className={styles.quantityText}>{getValue()}</span>
              <ButtonBar as="span" className={styles.changeButtonBar}>
                <Button
                  label={"Change"}
                  onClick={() => {
                    history.push(`/business/${businessId}/subscriptions/${subscription.id}`);
                  }}
                  disabled={disabled}
                  size="xxs"
                  discreet
                  primary
                  title={disabled ? title : undefined}
                />
              </ButtonBar>
            </div>
          );
        },
        meta: { primary: true },
      }),
      subscriptionColumnHelper.accessor("state", {
        header: () => <span>Status</span>,
        cell: ({ getValue }) => (
          <div className={clsx(styles.status, styles[getValue()] ?? styles.defaultState)}>{getValue()}</div>
        ),
      }),
    ];

    const contextMenuColumn = subscriptionColumnHelper.accessor("id", {
      ...contextMenuColDef,
      id: "actions",
      cell: ({ row }) => {
        const external = business?.external;
        const subscription = row.original;
        const fromChildBusinessId =
          isBusinessSubscription(subscription) && typeof subscription.fromChildBusinessId === "string";

        if (external) {
          return null;
        }

        return (
          <BusinessSubscriptionContextMenu fromChildBusinessId={fromChildBusinessId} subscription={subscription} />
        );
      },
    });

    if (someChildrenSubscriptions) {
      const fromChildBusinessIdColumn = subscriptionColumnHelper.accessor("fromChildBusinessId", {
        header: () => <span className="subscriptionsPlan">From Child Business</span>,
        cell: ({ getValue }) => {
          // assert as react-table (or TS?) doesn't know how to infer Unions
          const fromChildBusinessId = getValue() as BusinessSubscription["fromChildBusinessId"];
          const childBusinessName = businessChildren?.find((child) => child.id === fromChildBusinessId)?.name;

          return <span>{childBusinessName ?? ""}</span>;
        },
      });

      return [...commonColumns, fromChildBusinessIdColumn, contextMenuColumn];
    }

    return [...commonColumns, contextMenuColumn];
  }, [someChildrenSubscriptions, business?.external, history, businessId, businessChildren]);

  const subscriptionsTable = useReactTable({
    data: subscriptions,
    columns: subscriptionsColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSubscriptionsSorting,
    state: {
      sorting: subscriptionsSorting,
    },
  });
  const subscriptionsTableRowModel = subscriptionsTable.getRowModel();
  const subscriptionsTableRows = useMemo(() => subscriptionsTableRowModel.rows, [subscriptionsTableRowModel]);

  return (
    <Table<BusinessSubscription | SubscriptionInfo>
      caption="Subscriptions"
      rows={subscriptionsTableRows}
      columns={subscriptionsTable.getAllColumns()}
      headerGroups={subscriptionsTable.getHeaderGroups()}
      noDataText="No subscriptions founded"
      loading={loading}
      headerGroupProps={{
        cellClassName: styles.tableHeader,
      }}
      rowProps={{
        cellClassName: styles.tableCell,
      }}
    />
  );
};
