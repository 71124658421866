import React, { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { User, UserManager } from "oidc-client-ts";
import decode from "jwt-decode";
import { type DecodedAccessToken } from "src/components/pages/Home/license";
import { hasAuthParams } from "src/services/hasAuthParams";
import { cleanAuthParams } from "src/services/cleanAuthParams";
import { type History } from "history";
import { keycloakAuthResponseMode, keycloakAuthResponseType, keycloakAuthScope } from "src/constants";
import { createRegistrationUrl } from "src/services/createRegistrationUrl";
import { debuglog } from "src/services/debuglog";
import { SilentRenewError, TokenExpiredError, SignInRedirectError, LogOutRedirectError } from "src/services/authErrors";
import { useQuery } from "src/hooks/useQuery";

export interface AuthContextProps {
  initialized: boolean;
  isNavigating: boolean;
  authenticated: boolean;
  clientId: string;
  idToken?: string;
  refreshToken?: string;
  token?: string;
  tokenParsed?: DecodedAccessToken;
  username?: string;
  login: (signInRedirectUri?: string) => void;
  logout: (postLogoutRedirectUri?: string) => void;
  /**
   * Redirects to the registration page of the identity provider.
   * @param signUpClientId defaults to the client id of the auth provider
   */
  signUp: (history: History, signUpRedirectUri: string, signUpClientId?: string) => void;
  updateToken: () => void;
  error?: SilentRenewError | TokenExpiredError | SignInRedirectError | LogOutRedirectError;
}

export const AuthContext = React.createContext<AuthContextProps | undefined>(undefined);

type Action =
  | { type: "INITIALIZED" | "USER_LOADED"; user: User | null }
  | { type: "ERROR"; error: Error }
  | { type: "NAVIGATOR_INIT"; navigator: NonNullable<State["activeNavigator"]> }
  | { type: "NAVIGATOR_CLOSE" };

interface State {
  /**
   * See [User](https://authts.github.io/oidc-client-ts/classes/User.html) for more details.
   */
  user?: User | null;
  /**
   * True when auth context has been initialized.
   *
   * This means:
   * 1. the auth code flow has been completed
   * 2. the tokens have been fetched
   */
  initialized: boolean;
  /**
   * True while the user has a valid access token.
   */
  authenticated: boolean;
  error?: SilentRenewError | TokenExpiredError | SignInRedirectError | LogOutRedirectError;
  /**
   * True while navigating to the identity provider.
   */
  isNavigating: boolean;
  /**
   * The navigator that is currently active.
   */
  activeNavigator?: "logInRedirect" | "logOutRedirect" | "signUpRedirect";
}

const initialState: State = {
  initialized: false,
  authenticated: false,
  isNavigating: false,
};

interface AuthProviderProps {
  children?: React.ReactNode;
  config: {
    /** The URL of the OIDC/OAuth2 provider */
    authority: string;
    clientId: string;
    /**
     * The URI to redirect to after the user has signed in.
     * @default window.location.href
     */
    signInRedirectUri?: string;
  };
}

export const AuthProvider = (props: AuthProviderProps) => {
  const query = useQuery();
  const {
    children,
    config: { authority, clientId, signInRedirectUri },
  } = props;
  const [userManager] = useState(
    () =>
      new UserManager({
        authority,
        client_id: clientId,
        redirect_uri: signInRedirectUri ?? window.location.href,
        automaticSilentRenew: true,
        disablePKCE: false,
        response_type: keycloakAuthResponseType,
        response_mode: keycloakAuthResponseMode,
        scope: keycloakAuthScope,
      }),
  );

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    void (async (): Promise<void> => {
      try {
        debuglog("[auth] intializing auth context");
        let user: User | void | null = null;

        // check if returning back from authority server
        if (hasAuthParams()) {
          debuglog("[auth] entering auth code flow");
          user = await userManager.signinCallback();
          debuglog(`[auth] successfully completed auth code flow; client id: ${userManager.settings.client_id}`);
          cleanAuthParams();
        }

        if (!user?.access_token) {
          debuglog("[auth] checking if user is already authenticated");
          user = await userManager.getUser();
          debuglog(`[auth] successfully got tokens; client id: ${userManager.settings.client_id}`);
        }

        dispatch({ type: "INITIALIZED", user });
        debuglog(`[auth] auth context initialized`);
      } catch (error: unknown) {
        dispatch({
          type: "ERROR",
          error: error instanceof Error ? error : new Error("Login failed"),
        });
      }
    })();
  }, [userManager]);

  // register to userManager events
  useEffect(() => {
    // event UserLoaded (e.g. initial load, silent renew success)
    const removeUserLoadedEventHandler = userManager.events.addUserLoaded((user: User) => {
      debuglog("[auth] token refreshed");
      dispatch({ type: "USER_LOADED", user });
    });

    const removeSilentRenewErrorEventHandler = userManager.events.addSilentRenewError((error: Error) => {
      debuglog("[auth] silent renew error", error);
      dispatch({ type: "ERROR", error: new SilentRenewError(error) });
    });

    const removeAccessTokenExpiringEventHandler = userManager.events.addAccessTokenExpiring(() => {
      debuglog("[auth] access token expiring");
    });

    const removeAccessTokenExpiredEventHandler = userManager.events.addAccessTokenExpired(() => {
      debuglog("[auth] access token expired");
      dispatch({ type: "ERROR", error: new TokenExpiredError() });
    });

    return () => {
      removeUserLoadedEventHandler();
      removeSilentRenewErrorEventHandler();
      removeAccessTokenExpiredEventHandler();
      removeAccessTokenExpiringEventHandler();
    };
  }, [userManager]);

  const login = useCallback(
    async (signInRedirectUri?: string) => {
      dispatch({
        type: "NAVIGATOR_INIT",
        navigator: "logInRedirect",
      });

      try {
        debuglog(`[auth] redirecting to identity provider ${signInRedirectUri}`);
        await userManager.signinRedirect({ redirect_uri: signInRedirectUri });
      } catch (error: unknown) {
        dispatch({
          type: "ERROR",
          error: new SignInRedirectError(error),
        });
      } finally {
        dispatch({ type: "NAVIGATOR_CLOSE" });
      }
    },
    [userManager],
  );

  const logout = useCallback(
    async (postLogoutRedirectUri = window.location.href) => {
      const nonAuthParams = query;

      nonAuthParams.delete("code");
      nonAuthParams.delete("state");
      nonAuthParams.delete("session_state");

      const idToken = state.user?.id_token;

      dispatch({
        type: "NAVIGATOR_INIT",
        navigator: "logOutRedirect",
      });

      try {
        const _postLogoutRedirectUri =
          // pass any non-auth params to the post logout redirect uri
          // @ts-expect-error .size is not in the current version of ts https://github.com/microsoft/TypeScript/issues/54466
          nonAuthParams.size > 0 ? `${postLogoutRedirectUri}?${nonAuthParams.toString()}` : postLogoutRedirectUri;

        debuglog(`[auth] redirecting to identity provider ${_postLogoutRedirectUri}`);
        await userManager.signoutRedirect({
          post_logout_redirect_uri: _postLogoutRedirectUri,
          id_token_hint: idToken,
        });
      } catch (error: unknown) {
        dispatch({
          type: "ERROR",
          error: new LogOutRedirectError(error),
        });
      } finally {
        dispatch({ type: "NAVIGATOR_CLOSE" });
      }
    },
    [query, state.user?.id_token, userManager],
  );

  const updateToken = useCallback(() => {
    userManager.revokeTokens(["access_token"]);
  }, [userManager]);

  const tokenParsed = useMemo(
    () => (state.user?.access_token ? decode<DecodedAccessToken>(state.user.access_token) : undefined),
    [state.user?.access_token],
  );

  const signUp = useCallback(
    async (history: History, signUpRedirectUri: string, signUpClientId?: string) => {
      dispatch({
        type: "NAVIGATOR_INIT",
        navigator: "signUpRedirect",
      });

      try {
        const registrationUrl = createRegistrationUrl(signUpClientId ?? clientId, signUpRedirectUri);

        debuglog(`[auth] redirecting to identity provider ${registrationUrl}`);
        history.push(registrationUrl);
      } catch (error: unknown) {
        dispatch({
          type: "ERROR",
          error: error instanceof Error ? error : new Error("Sign up redirect failed", { cause: error }),
        });
      } finally {
        dispatch({ type: "NAVIGATOR_CLOSE" });
      }
    },
    [clientId],
  );

  return (
    <AuthContext.Provider
      value={{
        initialized: state.initialized,
        isNavigating: state.isNavigating,
        authenticated: state.authenticated,
        clientId,
        idToken: state.user?.id_token,
        refreshToken: state.user?.refresh_token,
        token: state.user?.access_token,
        tokenParsed,
        username: tokenParsed?.preferred_username,
        login,
        logout,
        signUp,
        updateToken,
        error: state.error,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "INITIALIZED":
      return {
        ...state,
        user: action.user,
        initialized: true,
        authenticated: action.user ? !action.user.expired : false,
        error: undefined,
      };
    case "USER_LOADED":
      return {
        ...state,
        user: action.user,
        authenticated: action.user ? !action.user.expired : false,
        error: undefined,
      };
    case "NAVIGATOR_INIT":
      return {
        ...state,
        isNavigating: true,
        activeNavigator: action.navigator,
      };
    case "NAVIGATOR_CLOSE":
      return {
        ...state,
        isNavigating: false,
        activeNavigator: undefined,
      };
    case "ERROR":
      debuglog(`[auth] ${action.error.message}`);

      if (action.error instanceof SilentRenewError || action.error instanceof TokenExpiredError) {
        return {
          ...state,
          error: action.error,
          authenticated: false,
        };
      }

      return {
        ...state,
        error: action.error,
      };
    default:
      return {
        ...state,
        error: new Error(`unknown type`),
      };
  }
}
