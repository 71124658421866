import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Notification } from "@k8slens/lds";

import { ProfileContext } from "src/providers/profile-provider";

import Layout from "src/components/Layout/Layout";
import { useBusinessJoinRequest } from "src/hooks/useBusinessJoinRequest";
import { useBusiness } from "src/hooks/useBusiness";
import { type Business } from "lens-platform-sdk";

export type Context = {
  businessId?: string;
  pendingRequests: Partial<Omit<ReturnType<typeof useBusinessJoinRequest>, "loading" | "error">>;
  business?: Business;
  loading: boolean;
  reload: () => void;
};

export const BusinessContext = createContext<Partial<Context> & { pendingRequests: Context["pendingRequests"] }>({
  pendingRequests: {},
});

const BusinessBase: React.FC<PropsWithChildren> = ({ children }) => {
  const { profile } = useContext(ProfileContext);
  const { businessId } = useParams<{ businessId?: string }>();

  const { business, loadingBusiness, refetchBusiness, errorLoadingBusiness } = useBusiness(businessId);
  const {
    loading: loadingRequests,
    error: errorLoadingRequests,
    ...pendingRequests
  } = useBusinessJoinRequest(businessId, "pending");

  const context = useMemo(
    () =>
      ({
        businessId,
        business,
        loading: loadingRequests || loadingBusiness,
        pendingRequests,
        reload: refetchBusiness,
      }) satisfies Context,
    [businessId, business, loadingBusiness, loadingRequests, pendingRequests, refetchBusiness],
  );

  return (
    <BusinessContext.Provider value={context}>
      <Layout
        className="bg-secondary"
        profile={profile}
        business={business}
        businessId={businessId}
        businessRequestCount={pendingRequests.count}
      >
        {(errorLoadingBusiness || errorLoadingRequests) && (
          <Notification
            className="mb-5"
            level="error"
            message={errorLoadingBusiness?.message || errorLoadingRequests?.message}
            type="closable"
          />
        )}
        {children}
      </Layout>
    </BusinessContext.Provider>
  );
};

export default BusinessBase;
