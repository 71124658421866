import { useContext } from "react";
import { Panel, ButtonBar } from "@k8slens/lds";

import { ProfileContext } from "src/providers/profile-provider";

import LinkButton from "src/components/Button/TrackedRouterButton";

import styles from "./ThankYou.module.css";

interface Props {
  loading?: boolean;
}

export const ThankYou: React.FC<Props> = ({ loading }) => {
  const { profile } = useContext(ProfileContext);

  return (
    <Panel
      className={styles.thankYou}
      loading={loading}
      header={
        <>
          <h2>Thank you for your Lens Subscription!</h2>
          <p>
            Subscription added to your Lens ID: <strong data-testid="thank-you-username">{profile?.username}</strong>
          </p>
        </>
      }
      footer={
        <>
          <ButtonBar className={styles.actions} type={"centered"}>
            <LinkButton to="/lens-launcher" target="_blank" label="Open Lens Desktop" primary />
          </ButtonBar>
        </>
      }
    >
      <img src="/static/media/thank-you-for-purchase-hero.svg" alt="Thank you!" />
    </Panel>
  );
};
