import { Panel, ButtonBar } from "@k8slens/lds";

import LinkButton from "src/components/Button/TrackedRouterButton";

import styles from "./ProTrialUsed.module.css";
import TrackedAnchor from "src/components/TrackedAnchor/TrackedAnchor";

interface Props {
  loading?: boolean;
}

export const ProTrialUsed: React.FC<Props> = ({ loading }) => {
  return (
    <Panel
      className={styles.proTrialUsed}
      loading={loading}
      header={
        <>
          <h2>Thank you for your Lens Subscription!</h2>
          <p>You have already activated your trial.</p>
        </>
      }
      footer={
        <>
          <ButtonBar className={styles.actions} type={"centered"}>
            <LinkButton to="/lens-launcher" target="_blank" label="Open Lens Desktop" primary />
          </ButtonBar>
        </>
      }
    >
      <div className={styles.trialEndNotification}>
        {/* eslint-disable-next-line max-len */}
        Need help convincing your boss? Need more time to onboard your teams? We’ll figure out something that works for
        you and your team.{" "}
        <TrackedAnchor href="https://public.refiner.io/s/eolx2e/9669d4a0-7a07-11ed-b065-2f290bc11892" target="_blank">
          Let us help!
        </TrackedAnchor>
      </div>
      <img src="/static/media/thank-you-for-purchase-hero.svg" alt="Thank you!" />
    </Panel>
  );
};
