import { LoadingIndicator } from "@k8slens/lds";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getRedirectAuthUri, isAuthorizedRedirectUri } from "src/services/getRedirectAuthToLensDesktopUrl";
import { sanitizeUrl } from "@braintree/sanitize-url";

export function RedirectToLensDesktop() {
  const location = useLocation();

  useEffect(() => {
    const redirectAuthUri = getRedirectAuthUri(location.search);

    if (isAuthorizedRedirectUri(redirectAuthUri)) {
      window.location.replace(sanitizeUrl(redirectAuthUri));
    }
  }, [location.search]);

  return <LoadingIndicator size="2xl" />;
}
