import { LoadingIndicator, Notification } from "@k8slens/lds";
import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import PublicLayout from "src/components/PublicLayout/PublicLayout";
import { useActivateProTrial } from "src/hooks/useActivateProTrial";
import { useAuth } from "src/hooks/useAuth";
import { getTrialState } from "src/utils/license";
import { ThankYouFooter } from "src/app/subscribe/ThankYouFooter";
import { ThankYou } from "src/app/subscribe/ThankYou";
import { ProTrialUsed } from "src/app/subscribe/ProTrialUsed";

export const ActivateProTrial = () => {
  const { activateProTrial, error, isPending, isSuccess } = useActivateProTrial();
  const { authenticated, tokenParsed } = useAuth();
  const trialState = getTrialState({
    license: { type: tokenParsed?.license_type, trial: Boolean(tokenParsed?.license_trial) },
    error,
  });

  useEffect(() => {
    if (authenticated && trialState === "available") {
      activateProTrial();
    }
  }, [activateProTrial, authenticated, trialState]);

  if (trialState === "used") {
    return (
      <PublicLayout title="Lens ID" footer={<ThankYouFooter />} size="xl">
        <ProTrialUsed />
      </PublicLayout>
    );
  }

  if (isSuccess) {
    return (
      <PublicLayout title="Lens ID" footer={<ThankYouFooter />} size="xl">
        <ThankYou />
      </PublicLayout>
    );
  }

  if (isPending || trialState === "available") {
    return <LoadingIndicator size="2xl" />;
  }

  if (error || trialState === "error") {
    <PublicLayout title="Lens ID" footer={<ThankYouFooter />} size="xl">
      <Notification type="flash" level="error" message={error?.message ?? "Something went wrong"} />
    </PublicLayout>;
  }

  // trialState === "locked" or other unknown state, redirect to home
  return <Redirect to={"/"} />;
};
