import { sanitizeUrl } from "@braintree/sanitize-url";
import { LoadingIndicator } from "@k8slens/lds";
import { useEffect } from "react";
import { getRedirectToIdpRegistrationUrl } from "src/services/getRedirectToIdpRegistrationUrl";

export const RedirectToIdpRegistration = () => {
  useEffect(() => {
    window.location.replace(sanitizeUrl(getRedirectToIdpRegistrationUrl()));
  }, []);

  return <LoadingIndicator size="2xl" />;
};
