export class SilentRenewError extends Error {
  constructor(cause: Error) {
    super(cause.message);
    this.cause = cause;
    Object.setPrototypeOf(this, SilentRenewError.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, SilentRenewError);
    }
  }
}

export class TokenExpiredError extends Error {
  constructor(cause?: Error) {
    super(cause?.message ?? "Token expired");
    this.cause = cause;
    Object.setPrototypeOf(this, TokenExpiredError.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, TokenExpiredError);
    }
  }
}

export class SignInRedirectError extends Error {
  constructor(cause: unknown) {
    super(cause instanceof Error ? cause?.message : "Sign in redirect failed");
    this.cause = cause;
    Object.setPrototypeOf(this, SignInRedirectError.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, SignInRedirectError);
    }
  }
}

export class LogOutRedirectError extends Error {
  constructor(cause: unknown) {
    super(cause instanceof Error ? cause?.message : "Log out redirect failed");
    this.cause = cause;
    Object.setPrototypeOf(this, LogOutRedirectError.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, LogOutRedirectError);
    }
  }
}
