/**
 * Removes the authentication parameters from the URL.
 */
export function cleanAuthParams(): void {
  const searchParams = new URLSearchParams(window.location.search);

  searchParams.delete("code");
  searchParams.delete("state");
  searchParams.delete("session_state");

  window.history.replaceState({}, "", `${window.location.pathname}?${searchParams.toString()}`);
}
