import { sanitizeUrl } from "@braintree/sanitize-url";
import { LoadingIndicator } from "@k8slens/lds";
import { useEffect } from "react";
import { getRedirectToIdpLogInUrl } from "src/services/getRedirectToIdpLogInUrl";

export const RedirectToIdpLogIn = () => {
  useEffect(() => {
    window.location.replace(sanitizeUrl(getRedirectToIdpLogInUrl()));
  }, []);

  return <LoadingIndicator size="2xl" />;
};
