import { useState } from "react";
import { useHistory } from "react-router-dom";

import { useInvitationKey, invitationKeyParameter } from "src/hooks/useInvitationKey";
import { useInvitation } from "src/hooks/useInvitation";
import { useAnalytics } from "src/hooks/useAnalytics";
import { acceptEmailInvitation } from "src/services/invitation";
import { delay } from "src/utils/delay";
import { removeQueryString } from "src/services/history";

import CurrentUser from "src/components/CurrentUser/CurrentUser";
import formStyles from "src/components/styles/form.module.css";

import Avatar from "src/components/Avatar/Avatar";

import styles from "./page.module.css";
import { useAuth } from "src/hooks/useAuth";

type Props = {
  changingUsername?: boolean;
};

const acceptLabel = "Accept";
const cancelLabel = "Cancel";

export const SpaceInvitation = ({ changingUsername }: Props) => {
  const { track, trackError, trackButtonClicked } = useAnalytics();
  const history = useHistory();
  const auth = useAuth();
  const invitationKey = useInvitationKey();
  const { invitation, error: invitationError } = useInvitation();
  const [isAccepting, setIsAccepting] = useState(false);
  const acceptDisabled = isAccepting || !invitation;
  const [acceptError, setAcceptError] = useState("");
  const errorText = acceptError || invitationError?.message || "";
  const [acceptSuccess, setAcceptSuccess] = useState("");
  const acceptInvitation = async () => {
    if (!invitation) {
      throw new Error("Invitation not found");
    }

    if (!invitationKey) {
      throw new Error("Invitation key not found");
    }

    trackButtonClicked(acceptLabel);

    try {
      setIsAccepting(true);

      await acceptEmailInvitation(invitation?.id as string, invitationKey, auth?.token || "");

      track("Invitation accepted");
      setAcceptSuccess("Invitation accepted.");

      // Clear previous error if set
      setAcceptError("");

      // user would have enough time to see "Invitation accepted."
      if (process.env.NODE_ENV !== "test") {
        await delay(4000);
      }

      // Remove query string to hide the invitation UI
      removeQueryString(history, invitationKeyParameter);
    } catch (error) {
      trackError("Invitation accepting failed");
      setAcceptError(error instanceof Error ? error.message : "");
      setAcceptSuccess("");
    } finally {
      setIsAccepting(false);
    }
  };
  const cancel = () => {
    trackButtonClicked(cancelLabel);
    removeQueryString(history, invitationKeyParameter);
  };

  return (
    <div className={formStyles.bg}>
      <div className={formStyles.size}>
        <div className={formStyles.upper}>
          <h1 className={styles.title}>Accept Invitation</h1>
          <div className={styles.invitationInfo}>
            <span className={styles.invitationInfoInvitedByText}>
              You have been invited to <b>{invitation?.spaceName}</b> space by <b>{invitation?.createdBy?.username}</b>.
            </span>
          </div>
        </div>
        <div className={styles.middle}>
          <div className={styles.middleContextWrapper}>
            <div>
              <h2 className={styles.whatIsSpaceHeader}>What is a space?</h2>
              <ul className={styles.whatIsSpaceUl}>
                <li className={styles.whatIsSpaceLi}>Easy and secure access to Kubernetes clusters</li>
                <li className={styles.whatIsSpaceLi}>Collaborate with team on cloud native development</li>
              </ul>
            </div>
            <div className={styles.spaceInfoWrapper}>
              <Avatar name={invitation?.spaceName ?? ""} className={styles.invitationAvatar} />
              <span className={styles.spaceInfoName}>{invitation?.spaceName ?? ""}</span>
            </div>
          </div>
        </div>
        <div className={formStyles.bottom}>
          {errorText && (
            <div className={styles.errorText}>
              <span>{errorText}</span>
            </div>
          )}
          {acceptSuccess && (
            <div className={styles.acceptSuccessText}>
              <span>{acceptSuccess}</span>
            </div>
          )}
          <div className={styles.btnGroup}>
            <button
              className={styles.cancel}
              disabled={acceptDisabled}
              type="button"
              aria-label="Cancel"
              onClick={cancel}
            >
              {cancelLabel}
            </button>
            <button
              className={formStyles.btn}
              disabled={acceptDisabled}
              type="button"
              aria-label="Accept"
              onClick={acceptInvitation}
            >
              {acceptLabel}
            </button>
          </div>
        </div>
        <CurrentUser changingUsername={changingUsername} />
      </div>
    </div>
  );
};
