import { useCallback } from "react";

import { useUsername } from "src/hooks/useUsername";
import { useAnalytics } from "src/hooks/useAnalytics";
import { useAuth } from "src/hooks/useAuth";

import formStyles from "src/components/styles/form.module.css";
import styles from "./CurrentUser.module.css";

type Props = {
  changingUsername?: boolean;
};

const label = "Login to your Lens ID";

const CurrentUser = ({ changingUsername }: Props) => {
  const { trackButtonClicked } = useAnalytics();
  const username = useUsername();
  const { logout } = useAuth();

  const handleLogout = useCallback(() => {
    trackButtonClicked(label);
    logout();
  }, [trackButtonClicked, logout]);

  return (
    <span className={styles.currentUser}>
      Logged in as <b className={styles.b}>{changingUsername ? "refreshing" : username}</b>. Not you?{" "}
      <button className={formStyles.link} tabIndex={0} role="link" type="button" onClick={handleLogout}>
        {label}
      </button>
    </span>
  );
};

export default CurrentUser;
